<template>
    <div>
        <productMenu @leave="leaveMenu" :isShow="showMenu.type == 'product'"></productMenu>
        <buttonMenu @leave="leaveMenu" v-for="item in btnMenus" :isShow="showMenu.type == item.type" :info="item">
        </buttonMenu>
    </div>
</template>

<script setup>
import productMenu from '@/components/menuComponents/productMenu.vue'
import buttonMenu from '@/components/menuComponents/buttonMenu.vue'
const showMenu = defineProps({ type: String, default: '' })
const emit = defineEmits(['leaveMenu'])

const leaveMenu = () => {
    emit('leaveMenu')
}
const btnMenus = [
    {
        type: 'about',
        imgArr: [
            require('../assets/image/about/tips.png'),
            require('../assets/image/about/develop.png'),
            require('../assets/image/about/honor.png'),
            require('../assets/image/about/cooperate.png')
        ],
        activeImg: [
            require('../assets/image/about/tipsActive.png'),
            require('../assets/image/about/developActive.png'),
            require('../assets/image/about/honorActive.png'),
            require('../assets/image/about/cooperateActive.png')
        ],
        menus: [{
            label: '微趣云简介',
            route: 'about',
            value: 0
        }, {
            label: '发展历程',
            route: 'about',
            value: 1
        }, {
            label: '资质与荣耀',
            route: 'about',
            value: 2
        }, {
            label: '合作伙伴',
            route: 'about',
            value: 3
        }]
    },
    {
        type: 'success',
        imgArr: [
            require('@/assets/image/suc1.png'),
            require('@/assets/image/suc2.png'),
            require('@/assets/image/suc3.png'),
            require('@/assets/image/suc4.png'),
        ],
        activeImg: [
            require('@/assets/image/case1Active.png'),
            require('@/assets/image/case2Active.png'),
            require('@/assets/image/case3Active.png'),
            require('@/assets/image/case4Active.png'),
        ],
        menus: [{
            label: '金融行业',
            route: 'success'
        }, {
            label: '政企行业',
            route: 'success'
        }, {
            label: '互联网行业',
            route: 'success'
        }, {
            label: '其他行业',
            route: 'success'
        }]
    },
    {
        type: 'standard',
        imgArr: [
            require('@/assets/image/suc1.png'),
            require('@/assets/image/suc2.png'),
            require('@/assets/image/suc3.png'),
        ],
        activeImg: [
            require('@/assets/image/case1Active.png'),
            require('@/assets/image/case2Active.png'),
            require('@/assets/image/case3Active.png'),
        ],
        menus: [{
            label: '银行标准解决方案',
            route: 'bank'
        }, {
            label: '政企标准解决方案',
            route: 'government'
        }, {
            label: '标准解决方案',
            route: 'standard'
        }]
    },
]

</script>

<style lang="scss">

</style>