<template>
    <div class="consult-box">
        <div class="consult-top">
            <div class="consult-phone" v-if="showActive == 0">销售咨询：13755022569</div>
            <div class="consult-icon-box" @mouseenter="showActive = 0" @mouseleave="showActive = null">
                <img class="consult-icon" src="@/assets/image/about/phone.png" />
            </div>
        </div>
        <div class="consult-icon-box">
            <img class="test-icon" src="@/assets/image/QrCode.png" v-if="showActive == 1" />
            <img class="consult-icon" src="@/assets/image/about/watch.png" @mouseenter="showActive = 1"
                @mouseleave="showActive = null" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
const showActive = ref()

</script>


<style lang="scss">
.consult-box {
    position: fixed;
    right: 0;
    bottom: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 100;

    .consult-top {
        display: flex;
        align-items: center;
        width: fit-content;
        margin-bottom: .04rem;

        .consult-phone {
            line-height: .72rem;
            font-size: .2667rem;
            font-weight: 500;
            color: #666666;
            padding: 0 .1733rem;
            background: #fff;
        }
    }

    .consult-icon-box {
        display: flex;
        position: relative;

        .consult-icon {
            padding: 10px;
            height: .4933rem;
            width: .4933rem;
            background: #00C8EE;
        }

        .test-icon {
            position: absolute;
            right: 100%;
            top: 0;
            height: 2rem;
            width: 2rem;
        }
    }
}
</style>