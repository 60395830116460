<template>
  <div class="banner" v-if="!['apply', 'home', 'login'].includes(router.currentRoute.value.name)">
    <div class="banner-info">
      <div class="banner-info-title" v-if="infos[router.currentRoute.value.name].title">
        {{ infos[router.currentRoute.value.name].title }}
      </div>
      <div class="banner-info-tips" v-if="infos[router.currentRoute.value.name].tips">
        {{ infos[router.currentRoute.value.name].tips }}
      </div>
      <div class="banner-info-btn" @click="applyProduct">申请试用 &nbsp; ></div>
    </div>
    <img :src="require(`@/assets/image/banners/${$route.name}.png`)" class="banner-img" />
  </div>
  <el-carousel style="position:relative;top:-1.2rem;left:0;right:0;z-index: 0;" height="9.2rem"
    indicator-position="outside" v-else-if="router.currentRoute.value.name == 'home' || router.currentRoute.value.name == 'productQuotation'" @change="changeSwiper">
    <el-carousel-item>
      <div class="banner" :style="{
        background: `url(${imgs[0]}) no-repeat`, backgroundSize: '100% 100%', height: '9.2rem'
      }">
        <div class="banner-info">
          <div class="banner-info-title" v-if="infos[router.currentRoute.value.name].title">
            {{ infos[router.currentRoute.value.name].title }}
          </div>
          <div class="banner-info-tips" v-if="infos[router.currentRoute.value.name].tips">
            {{ infos[router.currentRoute.value.name].tips }}
          </div>
        </div>
        <img :src="require('@/assets/image/banners/home.png')" class="banner-img" />
      </div>
    </el-carousel-item>
    <el-carousel-item>
      <div class="swiper-box"
        :style="{ background: `url(${imgs[1]}) no-repeat`, backgroundSize: '100% 100%', height: '9.2rem', }">
        <div>国内第一家“云通信+云服务”</div>
        <div>双核发展的企业移动应用服务公司</div>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ElCarousel, ElCarouselItem } from "element-plus";
import { ref } from "vue";
const router = useRouter();
const infos = {
  home: { title: "微趣云通信\n值得信赖的企业云通信平台" },
  productQuotation:  { title: "微趣云通信\n值得信赖的企业云通信平台" },
  about: {
    title: "微趣云通信 值得信赖的企业云通信平台",
    text: "稳定/高效/融合/安全/便捷",
  },
  bank: { title: "云通信银行行业解决方案" },
  government: { title: "云通信政企行业解决方案" },
  standard: { title: "云通信标准解决方案" },
  productQuotation: {title: "了解套餐价格，选择适合您的购买方案"},
  InternationalSms: {
    title: "国际短信平台",
    tips:
      "国际短信平台是通过第三方短信服务商提供的短信接口与互联网连接，实现企业与客户无缝连接，企业可以指定号码批量发送短信来达到品牌推广营销的目的。",
  },
  cloudSms: { title: "MOS短信云平台", tips: "专业、高效、安全，值得信赖的云通信管家" },
  sVoice: { title: "智能短信助手", tips: "可免费试用，手机端一键群发，简单快捷" },
  iccMiddle: {
    title: "ICC融合通信中台",
    tips:
      "通过通信基建中台化，实现融合消息发送、统一消息管理，通信渠道监控。以开放敏捷的架构，提升企业服务效率，实现一体化精准营销。结合融媒体管理平台、智能营销系统、全媒体智能客服系统、全渠道大数据分析等拓展服务应用，构建完整通信生态系统及通信链闭环。实现通信综合治理，通信成本降低，通信生态系统搭建。",
  },
  voicePaas: {
    title: "语音PaaS平台",
    tips:
      "将传统运营商的通信能力封装成API接口，企业开发者只需通过接口调用，即可为自家业务接入语音验证码、语音通知、隐私通话、点击通话、 呼叫中心API等不同场景下的通信能力",
  },
  callCenter: {
    title: "呼叫中心系统 ",
    tips: "全面满足企业联络需求，帮助企业为客户提供更优质的服务",
  },
  callbackSystem: {
    title: "回拨系统 ",
    tips: "回拨是指由客户主动发起的呼叫服务，客户在平台绑定自己的手机号码，用绑定的手机号码拨打B端号码，拨号后立刻挂断电话，等待响铃，平台会先呼叫绑定的手机号，再呼叫B端号码，双方接通后建立起正常通话，根据需求可实现多种方式的外显号码。",
  },
  numberMan: {
    title: "虚拟数字人 ",
    tips:
      "虚拟数字人是一项将人工智能技术应用于业务场景的整合技术方案。方案基于达摩院在语音、图像、3D美术、自然语言处理等领域的技术优势，提供数字人开放平台（面向开发者）、数字人应用市场（面向终端用户）、数字人资产服务等产品及服务。同时虚拟数字人具备多模态的表达与交互能力，具有广阔的应用场景",
  },
  cloudShortChain: {
    title: "智能云短链 ",
    tips:
      "改变传统的营销短信概念，为每一条带有短链接的营销短信提供跟踪、统计和分析的功能，分析短信营销效果，为二次营销和转化提供更多决策数据",
  },
  videoSms: {
    title: "视频短信",
    tips:
      "目标用户发送“文案+图片+视频”的一种新型短信业务，提供更多媒介给短信客户使用，其展现形式丰富，内容更饱满。",
  },
  jobNo: { title: "码号服务", tips: "码号代办、呼叫、落地及二次落地服务" },
  privacyNo: {
    title: "隐私号",
    tips:
      "为保护通话双方个人手机号码不被泄露，提升隐私保护安全，使用手机隐私号码作为通话双方的中间号码，手机隐私号码不需要额外增加SIM卡",
  },
  cloudServe: {
    title: "云服务器ECS",
    tips:
      "云服务器 ECS（Elastic Compute Service）是一种安全可靠、弹性可伸缩的云计算服务，助您降低 IT 成本，提升运维效率，使您更专注于核心业务创新。",
  },
  cloudServeSql: {
    title: "云数据库RDS MySQL 版",
    tips:
      "云数据库RDS MySQL 版是全球最受欢迎的开源数据库之一，作为开源软件组合 LAMP（Linux + Apache + MySQL + Perl/PHP/Python） 中的重要一环，广泛应用于各类应用场景。",
  },
  objectStrorage: {
    title: "对象存储OSS",
    tips:
      "阿里云对象存储OSS（Object Storage Service）是一款海量、安全、低成本、高可靠的云存储服务，提99.9999999999%(12个9)的数据持久性，99.995%的数据可用性。多种存储类型供选择，全面优化存储成本",
  },
  loadBlancer: {
    title: "负载均衡 SLB",
    tips:
      "阿里云负载均衡（Server Load Balancer，简称SLB）是云原生时代应用高可用的基本要素。通过将流量分发到不同的后端服务来扩展应用系统的服务吞吐能力，消除单点故障并提升应用系统的可用性。 阿里云SLB包含面向4层的网络型负载均衡NLB、面向7层的应用型负载均衡ALB和传统型负载均衡CLB，是阿里云官方云原生网关。",
  },
  realDetection: {
    title: "实人认证",
    tips:
      "实人认证是对用户身份信息真实性核验的服务，通过金融级生物识别，大数据分析和人工智能技术，为用户和机构提供安全又便捷的数字身份识别解决方案。",
  },
  oneClickLogin: {
    title: "一键登录",
    tips:
      "整合三大运营商特有的网关认证能力，一步验证手机号码和应用所在的手机SIM卡号码的一致性，升级短信验证码体验，并提供仅限本机操作的防控，安全高效。",
  },
  outboundAi: {
    title: '智能外呼机器人',
    tips: '智能外呼机器人基于语音识别与合成、机器学习和自然语言理解等技术，根据业务场景自动发起智能机器人电话外呼任务，通过人与机器人的语音对话交互收集业务结果，并对数据加以统计处理，获取用户反馈。'
  },
  numberClear: {
    title: "号码标记清洗",
    tips:
      "行业上手机被标记了，用户不接，可以提升接通率。先取消，后付费，远低同行报价！全程人工处理，百分比取消！一次取消，终身服务，7*24小时人工售后。",
  },
  success: {
    title: "十年通信行业深耕千家十亿级标杆领航",
  },
  marketingSms: {
    title: "营销短信",
    tips: '营销短信主要是以移动数据通信为主要技术手段，将短信发送到用户手机中，借此达到营销目的。'
  },
  noticeSms: {
    title: "通知短信",
    tips: '所谓通知，就是把某事项告诉需要知道该事项的人。而短信内容可以以文本形式完整的描述事项，短信的手机号码则可以精准地定位到需要知道该事项的人，且不可回避。故此，通知短信相较于微信通知或app推送而言，是更有价值的存在。'
  },
  codeSms: {
    title: "验证码短信",
    tips: '验证码短信是通过发送验证码到手机的一种有效的校验用户身份的方式，被广泛运用于具有用户概念的软件中，包括但不限于游戏、网站或各种APP。'
  },
  safetyReview: {
    title: '内容安全审核',
    tips: '内容安全审核基于深度学习技术，提供图片、视频、语音、文字、网页等多媒体的内容风险智能识别和审核服务，帮助用户发现色情、暴恐、政治敏感等风险内容，大幅度降低人工审核成本。'
  },
  threeElement: {
    title: '运营商三要素',
    tips: '1、 运营商数据核验产品包含4种api接口：手机三要素简版、手机三要素核验详版、手机在网状态、手机在网时长。身份三要素实名制认证（手机号+姓名+身份证号），根据用户授权快速核对姓名和身份证号码核对是否一致，详版可返回明确不一致原因。毫秒级响应，实时返回结果。数据合规、通道稳定。'
  }
};
const applyProduct = () => {
  router.push({ name: 'apply' })
}
const index = ref(0)
const imgs = [require('@/assets/image/banner.png'), require('@/assets/image/banner2.png')]
const changeSwiper = (idx) => {
  index.value = idx
}
</script>

<style lang="scss" scoped>
.swiper-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :first-child {
    font-size: .7rem;
    font-weight: bold;
    color: #FFFFFF;
    padding-bottom: .6667rem;
  }

  :last-child {
    font-size: .5rem;
    font-weight: bold;
    color: #FFFFFF;
  }
}

::v-deep .el-carousel__indicators--outside {
  position: relative !important;
  top: -100px;
  right: -100px;
}

::v-deep .el-carousel__indicators--horizontal {
  left: 0 !important;
}

::v-deep .el-carousel__indicators--outside button {
  width: .3333rem;
  height: .3333rem;
  background: #999999;
  border-radius: .1467rem;
}

::v-deep .el-carousel__indicator.is-active button {
  width: 1.2933rem;
  height: .3333rem;
  background: #EEA526;
  border-radius: .1467rem;
}

.home-btns {
  display: flex;
  margin-top: 1.3333rem;

  .home-btn {
    width: 3.48rem;
    height: 1.0533rem;
    border-radius: .1467rem;
    margin-right: .84rem;
    font-size: .3333rem;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 1.0533rem;
    cursor: pointer;
    text-decoration: none;
  }
}

.home-btn:first-child {
  background: #eea526;
}

.home-btn:last-child {
  background: #00be95;
}

@media screen and (max-width:769px) {
  .banner {
    padding: 0 5%;

    .banner-info-title {
      margin-bottom: .2rem;
      font-size: .5rem;
    }

    .banner-info-tips {
      margin-bottom: .2rem;
    }

    &-img {
      max-height: 50%;
    }
  }
}

@media screen and (min-width:769px) {
  .banner {
    padding: 0 2.6667rem;

    .banner-info-title {
      line-height: 1.2rem;
      margin-bottom: 0.9333rem;
      font-size: .7rem;
    }

    .banner-info-tips {
      max-width: 60%;
      margin-bottom: 1.5467rem;
      line-height: .4667rem;
    }

    &-img {
      max-height: 65%;
    }
  }
}

.banner {
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .banner-info {
    flex: 1;
    text-align: left;

    &-title {
      white-space: pre-line;
      font-weight: bold;
      color: #ffffff;
    }

    &-tips {
      font-size: 0.3333rem;
      font-weight: 500;
      color: #ffffff;
    }

    &-btn {
      text-align: center;
      width: 3.48rem;
      height: 1.0533rem;
      line-height: 1.0533rem;
      font-size: 0.3333rem;
      font-weight: 600;
      color: #ffffff;
      background: #eea526;
      border-radius: 0.3067rem;
      cursor: pointer;
    }
  }

  &-img {
    // max-height: 65%;
    // max-width: 50%; 
  }
}
</style>
