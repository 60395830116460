<template>
  <div id="app" v-if="$route.name" :style="{
    background: $route.name == 'home' ? '' : 'url(' + banner + ') no-repeat',
    backgroundSize: ['apply', 'login'].includes($route.name) ? '100% 675px' : '100% 9.2rem',
  }">
    <head-box ref="head"></head-box>
    <bannerBox></bannerBox>
    <div :style="{ minHeight: ['apply', 'login'].includes($route.name) ? '11rem' : '' }">
      <router-view />
    </div>
    <foot-box></foot-box>
    <messageBoard></messageBoard>
    <consultBox></consultBox>
  </div>
</template>

<script>
import headBox from "../src/components/headerBox.vue";
import footBox from "../src/components/footBox.vue";
import bannerBox from "./components/bannerBox.vue";
import messageBoard from "./components/messageBoard.vue";
import consultBox from './components/consultBox.vue'
export default {
  components: {
    headBox,
    footBox,
    bannerBox,
    messageBoard,
    consultBox
  },
  data() {
    return {
      banner: require('../src/assets/image/banner.png')
    }
  },
  mounted() { 
    window.localStorage.setItem('clientWidth', document.body.clientWidth)
  }
};
</script>

<style lang="scss">
@import "@/common/normal.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}
</style>
