import axios from "axios";

const API = axios.create({
    // baseURL: 'http://119.23.37.54:8099',
    baseURL: 'https://api.weiqucloud.com',
    // baseURL: '/dev',
    headers: { "Access-Control-Allow-Origin": "*" },
    timeout: 2000
})

export default API 