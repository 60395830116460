<template>
    <div class="container">
        <div v-if="!isHide">
            <div class="head-box">
                <div class="head-box-label">留言板</div>
                <div style="padding: .2667rem;" @click="changeStatus(true)">
                    <div class="head-box-close"></div>

                </div>
            </div>
            <el-form ref="ruleFormRef" :model="from" :rules="rules" size="default" style="padding: .2533rem .28rem;">
                <el-form-item prop="contactContent" style="margin-bottom: .2667rem;">
                    <el-input type="textarea" v-model="from.contactContent" placeholder="请在此输入留言内容，我们会尽快与您联系。（必填）"
                        rows="5">
                    </el-input>
                </el-form-item>
                <el-form-item prop="name" style="margin-bottom: .2667rem;">
                    <el-input v-model="from.name" placeholder="姓名" size="large">
                        <template #prefix>
                            <img src="@/assets/image/messageName.png" style="width: .2133rem; height: .2133rem;" />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="phone" style="margin-bottom: .2667rem;">
                    <el-input v-model="from.phone" placeholder="电话（必填）" size="large">
                        <template #prefix>
                            <img src="@/assets/image/messageName.png" style="width: .2133rem; height: .2133rem;" />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="email" style="margin-bottom: .2667rem;">
                    <el-input v-model="from.email" placeholder="邮箱" size="large">
                        <template #prefix>
                            <img src="@/assets/image/messageName.png" style="width: .2133rem; height: .2133rem;" />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="companyName" style="margin-bottom: .2667rem;">
                    <el-input v-model="from.companyName" placeholder="公司名称" size="large">
                        <template #prefix>
                            <img src="@/assets/image/messageName.png" style="width: .2133rem; height: .2133rem;" />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="comfirm-btn" type="primary" @click="submitForm(ruleFormRef)">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div v-else>
            <div class="head-box" style="border-radius: .1333rem;">
                <div class="head-box-label">留言板</div>
                <div style="padding: .2667rem;" @click="changeStatus(false)">
                    <img src="@/assets/image/open.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { ElForm, ElFormItem, ElButton, ElInput, ElMessage } from 'element-plus'
import axios from '@/common/axios'
const from = reactive({
    name: "",
    phone: "",
    email: "",
    companyName: "",
    contactContent: ""
})
const rules = reactive({
    name: [
        { required: true, message: '请输入姓名', trigger: 'blur' },
        { min: 2, max: 15, message: '请输入正确姓名', trigger: 'blur' },
    ],
    phone: [
        { required: true, message: '请输入手机号', trigger: 'blur', },
        { min: 11, max: 11, message: '请输入正确手机号', trigger: 'blur' },
    ],
    email: [
        { required: true, message: '请输入邮箱', trigger: 'blur', },
        { min: 5, message: '请输入正确邮箱', trigger: 'blur' },
    ],
    companyName: [
        { required: true, message: '请输入公司名', trigger: 'blur', },
        { min: 2, max: 20, message: '请输入正确公司名', trigger: 'blur' },
    ],
    contactContent: [
        { required: true, message: '请输入留言', trigger: 'blur', },
        { min: 5, message: '请输入不少于5字留言', trigger: 'blur' },
    ],
})
const isHide = ref(false)
const changeStatus = (status) => {
    isHide.value = status
}
const ruleFormRef = ref()
const submitForm = async (formEl) => {
    if (!formEl) return
    await formEl.validate((valid, fields) => {
        if (valid) {
            send()
        } else {
        }
    })
}

const send = async () => {
    try {
        const res = await axios.post('/api/guest/addMessageBoard', from)
        ElMessage({
            message: '提交成功',
            type: 'success',
        })
        isHide.value = true
        from = {
            name: "",
            phone: "",
            email: "",
            companyName: "",
            contactContent: ""
        }
    } catch (error) {
    }
}
</script>

<style lang="scss">
.container {
    position: fixed;
    bottom: .2667rem;
    right: .2667rem;
    background: #fff;
    box-shadow: 0 6px 12px 0 rgb(0 0 0 / 50%);
    width: 5rem;
    border-radius: 10px;
    z-index: 10000;

    .head-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #4A88FF;
        padding-left: .2933rem;
        border-radius: 10px 10px 0 0;

        &-label {
            font-size: .2667rem;
            font-weight: 500;
            color: #F4F4F4;
        }

        &-close {
            cursor: pointer;
            margin: .2667rem;
            width: .2133rem;
            height: .0533rem;
            background: #FFFFFF;
            border-radius: .0267rem;
        }
    }

    .comfirm-btn {
        margin-top: .2667rem;
        width: 1.1733rem;
        height: .7333rem;
        line-height: .7333rem;
        background: #4486E8;
        border-radius: .0933rem;
        margin-left: auto;
        font-size: .2133rem;
        font-weight: 500;
        color: #FFFFFF;
    }
}
</style>