<template>
  <div v-if="props.isShow && active">
    <div class="product-menu" @mouseleave="leave">
      <div class="menu-list">
        <div class="menu-item" v-for="(item, idx) in props.info.menus" @click="handleMenu(item)"
          @mouseenter="index = idx" @mouseleave="index = null">
          <img class="menu-img" :src="index == idx ? props.info.activeImg[idx] : props.info.imgArr[idx]" />
          <div class="menu-text">{{ item.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({ info: Object, default: {}, isShow: Boolean, default: false });
const emit = defineEmits(['leave'])
const active = ref(true);
watch(props, (newVal) => {
  if (newVal.isShow) {
    active.value = true;
  }
});
const index = ref(null);
const leave = () => {
  active.value = false
  emit('leave')
}
const handleMenu = (item) => {
  active.value = false;
  router.push({
    name: item.route,
    query: {
      value: item.value || ''
    }
  });
  index.value = null
  emit('leave')
};
</script>

<style lang="scss" scoped>
.product-menu {
  z-index: 100;
  position: absolute;
  top: 1.2rem;
  left: 0;
  right: 0;
  height: 1.2667rem;
  animation: topIn 0.5s ease;
  background: #eef4ff;
  display: flex;

  .active-img {
    margin-left: 2.6667rem;
    transform: translateY(120%);
    height: 0.5333rem;
    width: 0.5333rem;
  }

  .menu-list {
    display: flex;
    justify-content: center;
    margin: 0 2.6667rem 0 0.3733rem;

    .menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.9333rem;
      height: 1.2667rem;
      border-right: 0.0133rem solid #1e2eb3;
      color: #333333;
      cursor: pointer;

      .menu-img {
        width: .4267rem;
        height: .4267rem;
      }

      .menu-text {
        font-size: .3rem;
        font-weight: 400;
        padding-left: 0.2rem;
      }
    }

    .menu-item:hover {
      background: #4a88ff;
      color: #fff;
    }

    :last-child {
      border: none;
    }
  }

  @keyframes topIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
</style>
