<template>
    <div v-if="props.isShow && active">
        <div class="product-menu" @mouseleave="leave">
            <div class="menu-item first-menu" v-for="item, idx in firstMenu">
                <img class="title-img" :src="item.icon" />
                <div class="menu-text-box">
                    <div class="title-text">{{ item.title }}</div>
                    <div class="menu-text" v-for="menu in item.list" @click="handleMenu(menu.route)"> {{ menu.label }}
                        <img v-if="menu.hot" class="hot-icon" src="@/assets/image/hot.png" />
                    </div>
                </div>
            </div>
            <div class="menu-list">
                <div :class="[[3, 4].includes(idx) ? 'trans menu-item' : 'menu-item']" v-for="item, idx in productMenu">
                    <img class="title-img" :src="item.icon" />
                    <div class="menu-text-box">
                        <div class="title-text">{{ item.title }}</div>
                        <div class="menu-text" v-for="menu in item.list" @click="handleMenu(menu.route)">
                            {{ menu.label }}
                            <img v-if="menu.hot" class="hot-icon" src="@/assets/image/hot.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
const props = defineProps({ isShow: Boolean, default: false })
const emit = defineEmits(['leave'])
const router = useRouter()
const active = ref(true)
watch(props, (newVal) => {
    if (newVal.isShow) {
        active.value = true
    }
})
const handleMenu = (route) => {
    active.value = false
    router.push({ name: route })
    leave()
}
const leave = () => {
    active.value = false
    emit('leave')
}
const firstMenu = [{
    title: '短信及企业级平台',
    icon: require('../../assets/image/header/message.png'),
    list: [
        { label: 'ICC 融合通信中台', route: 'iccMiddle' },
        { label: 'MOS短信云平台', route: 'cloudSms', hot: true },
        { label: '国际短信平台', route: 'InternationalSms' },
        { label: '智能短信助手', route: 'sVoice' },
        { label: '验证码短信', route: 'codeSms' },
        { label: '通知短信', route: 'noticeSms' },
        { label: '营销短信', route: 'marketingSms' },
    ],
}]
const productMenu = [
    {
        title: '移动认证',
        icon: require('../../assets/image/header/auth.png'),
        list: [
            { label: '一键登录', route: 'oneClickLogin' },
            { label: '号码标记清洗', route: 'numberClear' },
        ]
    }, {
        title: '富媒体通信',
        icon: require('../../assets/image/header/comm.png'),
        list: [
            { label: '智能云短链【AIM】', route: 'cloudShortChain' },
            { label: '视频短信', route: 'videoSms' },
        ]
    }, {
        title: '云计算产品',
        icon: require('../../assets/image/header/cloudComputing.png'),
        list: [
            { label: '负载均衡 SLB', route: 'loadBlancer' },
            { label: '云服务器ECS', route: 'cloudServe' },
            { label: '云数据库 RDS MySQL 版', route: 'cloudServeSql' },
            { label: '对象存储 OSS', route: 'objectStrorage' },
        ]
    }, {
        title: '码号服务',
        icon: require('../../assets/image/header/codeNo.png'),
        list: [
            { label: '工作号服务', route: 'jobNo' },
            { label: '隐私号业务', route: 'privacyNo', hot: true },
        ]
    }, {
        title: '语音PaaS',
        icon: require('../../assets/image/header/voice.png'),
        list: [
            { label: '呼叫中心系统', route: 'callCenter', hot: true },
            { label: '语音验证码', route: 'voicePaas' },
            // { label: '回拨外呼系统', route: 'callbackSystem' },
            { label: '数字代言人', route: 'numberMan' },
            { label: '智能外呼机器人', route: 'outboundAi' },
        ]
    }, {
        title: '运营检测',
        icon: require('../../assets/image/header/operate.png'),
        list: [
            { label: '活体实人检测', route: 'realDetection' },
            { label: '内容安全审核', route: 'safetyReview' },
            { label: '运营三要素', route: 'threeElement', hot: true },
        ]
    },]


</script>

<style lang="scss" scoped>
.product-menu {
    position: absolute;
    top: 1.2rem;
    left: 0;
    right: 0;
    animation: topIn .5s ease;
    background: #EEF4FF;
    z-index: 100;
    display: flex;
    padding: .4rem 10% .4rem 15%;

    .first-menu {
        width: 25% !important;
    }

    .menu-list {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        :nth-child(3) {
            padding-bottom: 60px;
        }
    }

    .trans {
        transform: translateY(-50px);
    }

    .menu-item {
        width: 30%;
        display: flex;
        align-items: flex-start;
        justify-content: left;
        margin-bottom: .4rem;

        .title-img {
            width: .4rem;
            height: .4rem;
            margin-right: .2667rem;
        }

        .menu-text-box {
            padding-bottom: .2667rem;
            text-align: left;

            .title-text {
                padding-bottom: .1867rem;
                font-size: .2667rem;
                font-weight: 400;
                color: #000000;
                height: .4rem;
                line-height: .4rem;
            }

            .menu-text {
                padding-bottom: .1333rem;
                font-size: .24rem;
                font-weight: 400;
                color: #000000;
                height: .4rem;
                line-height: .4rem;
                cursor: pointer;
                display: flex;
                align-items: center;

                .hot-icon {
                    margin-left: .1333rem;
                    width: .36rem;
                    height: .36rem;
                }
            }

            .menu-text:hover {
                color: #4A88FF;
            }
        }
    }

    @keyframes topIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}
</style>