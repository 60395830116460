<template>
  <div class="header" v-if="clientWidth > 769">
    <img class="head-logo" @click="toHome" src="@/assets/image/logo.png" />
    <div class="menu-list">
      <div @mouseenter="showHeaderMenu(item)" :class="[showType == item.route ? 'active menu-item' : 'menu-item']"
        v-for="item in menuArr" @click="hanldMenu(item)">
        {{ item.label }}
      </div>
    </div>
    <div class="head-right">
      <div class="right-btns">
        <!-- <div class="btn-login" @click="getLoginArr">登录</div> -->
        <RouterLink class="btn-login" :to="{ name: 'login' }">登录</RouterLink>
        <RouterLink class="btn-try" :to="{ name: 'apply' }">免费试用</RouterLink>
      </div>
      <div class="right-box">
        <div class="right-phone">全国统一销售热线</div>
        <div class="right-phone">13755022569</div>
      </div>

    </div>
    <HeaderMenu :type="showType" @leave-menu="leaveHeader"></HeaderMenu>
  </div>
  <el-affix :offset="0" v-else>
    <div class="mobile-header">
      <img class="mobile-header-logo" src="@/assets/image/logo.png"  @click="toHome" />
      <div class="mobile-header-right">
        <RouterLink class="mobile-try" :to="{ name: 'apply' }">免费试用</RouterLink>
        <img class="mobile-status-icon" src="@/assets/image/header/open.png" @click="menuShow = !menuShow" />
      </div>
    </div>
  </el-affix>
  <mobileMenu v-model:menuShow="menuShow"></mobileMenu>
</template>

<script setup>
import mobileMenu from "./mobileMenu.vue";
import HeaderMenu from "@/components/headerMenu.vue";
import { ElAffix } from 'element-plus';
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const menuArr = [
  { label: "首页", type: "link", route: "home" },
  { label: "产品与服务", type: "link", route: "product" },
  { label: "产品报价", type: "link", route: "productQuotation"},
  { label: "解决与方案", type: "link", route: "standard" },
  { label: "成功案例", type: "link", route: "success" },
  { label: "关于云通信", type: "link", route: "about" },
];
let showType = ref("");
const hanldMenu = (item) => {
  if (item.route == 'product') {
    return
  }
  router.push({ name: item.route });
};
const toHome = () => {
  router.push({ name: 'home' })
}
const showHeaderMenu = (item) => {
  showType.value = item.route;
};
const leaveHeader = () => {
  showType.value = "";
};
const menuShow = ref(false)
const clientWidth = ref()

onMounted(() => {
  clientWidth.value = document.body.clientWidth
})
</script>

<style lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  position: relative;

  .head-logo {
    z-index: 1000;
    height: 0.6rem;
    width: 1.9333rem;
    padding-right: 5%;
    border-right: 0.0133rem solid #ffffff;
    cursor: pointer;
  }

  .menu-list {
    display: flex;
    flex: 1;
    justify-content: space-around;
    z-index: 1000;

    .menu-item {
      height: 1.2rem;
      line-height: 1.2rem;
      font-size: .3rem;
      font-weight: 600;
      color: #ffffff;
      position: relative;
      cursor: pointer;
    }

    .active::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: #fff;
      content: "";
      height: 0.1067rem;
      width: 1.6267rem;
    }
  }

  .head-right {
    display: flex;
    align-items: center;
    z-index: 1000;

    .right-btns {
      display: flex;

      .btn-login {
        font-weight: bold;
        width: 1.0933rem;
        height: 0.56rem;
        line-height: 0.56rem;
        background: #ffffff;
        border-radius: 0.28rem;
        color: #4a88ff;
        margin-right: 0.2933rem;
        text-decoration: none;
      }

      .btn-try {
        font-weight: bold;
        width: 1.6267rem;
        height: 0.56rem;
        line-height: 0.56rem;
        border-radius: 0.28rem;
        color: #fff;
        border: 0.0133rem solid #ffffff;
        text-decoration: none;
        margin-right: 0.2667rem;
      }
    }

    .right-box {
      .right-phone {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
}

.header::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  content: "";
  height: .0133rem;
  width: 100%;
  z-index: 1000;
}

.mobile-header {
  height: 1.2rem;
  padding: 0 .1867rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-logo {
    z-index: 1000;
    height: .8rem;
  }

  &-right {
    z-index: 1000;
    display: flex;
    align-items: center;

    .mobile-try {
      width: 1.8rem;
      height: .7rem;
      line-height: .7rem;
      text-align: center;
      background: #FFFFFF;
      border-radius: .16rem;
      font-size: .1867rem;
      font-weight: 600;
      color: #4A88FF;
      text-decoration: none;
    }

    .mobile-status-icon {
      margin-left: .6rem;
      height: .6rem;
    }
  }
}
</style>
