<template>
  <div
    v-if="!hideArr.includes(router.currentRoute.value.name)"
    class="apply-box"
    :style="{
      background: 'url(' + getImgUrl + ') no-repeat',
      backgroundSize: '100% 6rem',
    }"
  >
    <div class="apply-title">申请试用，即享更多优质服务</div>
    <div class="apply-btn" @click="toApply">申请试用</div>
  </div>
  <div class="foot" v-if="clientWidth > 769">
    <div class="foot-left">
      <div class="menu-item" v-for="(item, index) in infoArr">
        <div class="item-title">{{ item.title }}</div>
        <div :class="index == 0 ? 'menu-list' : ''">
          <div class="menu" v-for="menu in item.list" @click="handleMenu(menu)">
            {{ menu.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="foot-right">
      <div class="right-title">联系我们</div>
      <!-- <div class="contact" v-for="contact in contactArr" @click="handleContact(contact)">{{ contact.title }}</div> -->
      <div class="contact" v-for="contact in contactArr">
        <a v-if="contact.url" target="_blank" :href="contact.url">{{
          contact.title
        }}</a>
        <div v-else>{{ contact.title }}</div>
      </div>
      <div style="display: flex">
        <div class="contacts" v-for="item in qrCodes">
          <img class="contacts-img" :src="item.icon" />
          <div class="contacts-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <mobileFoot :menuArr="infoArr"></mobileFoot>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import mobileFoot from "@/components/mobleFoot.vue";
import { useRouter } from "vue-router";

const infoArr = [
  {
    title: "产品一览",
    list: [
      { label: "MOS短信云平台", route: "cloudSms" },
      { label: "活体实人检测", route: "realDelection" },
      { label: "国际短信平台", route: "InternationalSms" },
      { label: "工作号服务", route: "jobNo" },
      { label: "小号外呼系统", route: "callCenter" },
      { label: "隐私号业务", route: "privacyNo" },
      { label: "回拨外呼系统", route: "callbackSystem" },
      { label: "智能云短链", route: "cloudShortChain" },
      { label: "一键登录", route: "oneClickLogin" },
      { label: "云服务器ECS", route: "cloudServe" },
    ],
  },
  {
    title: "解决方案",
    list: [
      { label: "标准解决方案", route: "standard" },
      { label: "银行解决方案", route: "bank" },
      { label: "政企解决方案", route: "government" },
    ],
  },
  {
    title: "关于微趣云通信",
    list: [
      { label: "微趣云通信简介", route: "about", value: 0 },
      { label: "发展历程", route: "about", value: 1 },
      { label: "资质荣誉", route: "about", value: 2 },
      { label: "合作客户", route: "about", value: 3 },
    ],
  },
];
const qrCodes = [
  { icon: require("@/assets/image/QrCode1.png"), text: "代理合作" },
  { icon: require("@/assets/image/QrCode.png"), text: "客服经理" },
];
const router = useRouter();
const contactArr = [
  {
    title: "销售咨询：13755022569",
    url: null,
  },
  {
    title: "Email：1747227652@qq.com",
    url: null,
  },
  {
    title: "湘ICP备17012916号-2",
    url: "http://beian.miit.gov.cn/",
  },
];
const handleMenu = (item) => {
  router.push({
    name: item.route,
    query: {
      value: item.value || "",
    },
  });
};
const handleContact = (item) => {
  console.log(JSON.stringify(item));
  if (item.url) {
    router.push({
      name: item.url,
    });
  }
};
const hideArr = [
  "apply",
  "login",
  "success",
  "about",
  "standard",
  "bank",
  "government",
];
const toApply = () => {
  window.scrollTo(0, 0);
  router.push({ name: "apply" });
};
const getImgUrl = computed(() => {
  return require(router.currentRoute.value.name == "home" ||
    router.currentRoute.value.name == "productQuotation"
    ? "@/assets/image/footApply2.png"
    : "@/assets/image/footApply.png");
});
const clientWidth = ref();
onMounted(() => {
  clientWidth.value = document.body.clientWidth;
});
</script>

<style lang="scss" scoped>
.apply-box {
  height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;

  .apply-title {
    font-size: 0.48rem;
    font-weight: 600;
    color: #ffffff;
  }

  .apply-btn {
    width: 3.48rem;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 0.3067rem;
    border: 0.04rem solid #ffffff;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }

  .apply-btn:hover {
    background: #fff;
    color: #4a88ff;
  }
}

.foot {
  display: flex;
  background: #2e364c;
  padding: 0.5867rem 0 0.6933rem;

  @media screen and (max-width: 769px) {
    .foot-left {
      width: 60%;
      border-right: 0.0133rem solid #4c4c4c;
    }
  }

  @media screen and (min-width: 769px) {
    .foot-left {
      width: 60%;
      display: flex;
      justify-content: space-evenly;
      border-right: 0.0133rem solid #4c4c4c;
    }
  }

  .foot-left {
    .menu-item {
      text-align: left;

      .item-title {
        font-size: 0.3333rem;
        font-weight: 500;
        color: #f4f4f4;
        padding-bottom: 0.6133rem;
      }

      .menu-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 330px;

        .menu {
          padding-bottom: 0.2667rem;
          width: 45%;
          font-size: 0.2667rem;
          font-weight: 400;
          color: #e6e6e6;
        }
      }

      .menu {
        font-size: 0.2667rem;
        font-weight: 400;
        padding-bottom: 0.2667rem;
        color: #e6e6e6;
        cursor: pointer;
      }

      .menu:hover {
        color: #4a88ff;
      }
    }
  }

  @media screen and (max-width: 769px) {
    .foot-right {
      padding-left: 0.4rem;
    }

    .contacts-img {
      width: 90%;
    }
  }

  @media screen and (min-width: 769px) {
    .foot-right {
      padding-left: 1.6933rem;
    }

    .contacts-img {
      height: 2rem;
      width: 2rem;
    }
  }

  .foot-right {
    text-align: left;

    .right-title {
      padding-bottom: 0.52rem;
      font-size: 0.3333rem;
      font-weight: 500;
      color: #f4f4f4;

    }

    .contact {
      font-size: 0.2133rem;
      font-weight: 400;
      color: #f4f4f4;
      padding-bottom: 0.28rem;

    }
    
    a:link {
        color: #ffffff;
        text-decoration: none;
      }

      a:visited {
        color: #ffffff;
        text-decoration: none;
      }

      a:hover {
        color: #f4f4f4;
        text-decoration: underline;
      }

    .contacts {
      margin-right: 0.8933rem;
      &-text {
        text-align: center;
        font-size: 0.2667rem;
        font-weight: 500;
        color: #f4f4f4;
      }
    }
  }
}
</style>
