import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('../views/apply.vue')
  },
  {
    path: '/bank',
    name: 'bank',
    component: () => import('../views/plan/bank.vue')
  },
  {
    path: '/government',
    name: 'government',
    component: () => import('../views/plan/government.vue')
  },
  {
    path: '/standard',
    name: 'standard',
    component: () => import('../views/plan/standard.vue')
  },
  {
    path: '/productQuotation',
    name: 'productQuotation',
    component: () => import('../views/productQuotation.vue')
  },
  
  {
    path: '/InternationalSms',
    name: 'InternationalSms',
    component: () => import('../views/product/InternationalSms.vue')
  },
  {
    path: '/cloudSms',
    name: 'cloudSms',
    component: () => import('../views/product/cloudSms.vue')
  },
  {
    path: '/sVoice',
    name: 'sVoice',
    component: () => import('../views/product/sVoice.vue')
  },
  {
    path: '/iccMiddle',
    name: 'iccMiddle',
    component: () => import('../views/product/iccMiddle.vue')
  },
  {
    path: '/voicePaas',
    name: 'voicePaas',
    component: () => import('../views/product/voicePaas.vue')
  },
  {
    path: '/callCenter',
    name: 'callCenter',
    component: () => import('../views/product/callCenter.vue')
  },
  {
    path: '/callbackSystem',
    name: 'callbackSystem',
    component: () => import('../views/product/callbackSystem.vue')
  },
  {
    path: '/numberMan',
    name: 'numberMan',
    component: () => import('../views/product/numberMan.vue')
  },
  {
    path: '/cloudShortChain',
    name: 'cloudShortChain',
    component: () => import('../views/product/cloudShortChain.vue')
  },
  {
    path: '/videoSms',
    name: 'videoSms',
    component: () => import('../views/product/videoSms.vue')
  },
  {
    path: '/jobNo',
    name: 'jobNo',
    component: () => import('../views/product/jobNo.vue')
  },
  {
    path: '/privacyNo',
    name: 'privacyNo',
    component: () => import('../views/product/privacyNo.vue')
  },
  {
    path: '/cloudServe',
    name: 'cloudServe',
    component: () => import('../views/product/cloudServe.vue')
  },
  {
    path: '/cloudServeSql',
    name: 'cloudServeSql',
    component: () => import('../views/product/cloudServeSql.vue')
  },
  {
    path: '/objectStrorage',
    name: 'objectStrorage',
    component: () => import('../views/product/objectStrorage.vue')
  },
  {
    path: '/loadBlancer',
    name: 'loadBlancer',
    component: () => import('../views/product/loadBlancer.vue')
  },
  {
    path: '/realDetection',
    name: 'realDetection',
    component: () => import('../views/product/realDetection.vue')
  },
  {
    path: '/oneClickLogin',
    name: 'oneClickLogin',
    component: () => import('../views/product/oneClickLogin.vue')
  },
  {
    path: '/numberClear',
    name: 'numberClear',
    component: () => import('../views/product/numberClear.vue')
  },
  {
    path: '/outboundAi',
    name: 'outboundAi',
    component: () => import('../views/product/outboundAi.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/success.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/marketingSms',
    name: 'marketingSms',
    component: () => import('../views/product/marketingSms.vue')
  },
  {
    path: '/noticeSms',
    name: 'noticeSms',
    component: () => import('../views/product/noticeSms.vue')
  },
  {
    path: '/codeSms',
    name: 'codeSms',
    component: () => import('../views/product/codeSms.vue')
  },
  {
    path: '/safetyReview',
    name: 'safetyReview',
    component: () => import('../views/product/safetyReview.vue')
  },
  {
    path: '/threeElement',
    name: 'threeElement',
    component: () => import('../views/product/threeElement.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
