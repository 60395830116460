<template>
    <el-drawer v-model="props.menuShow" direction="ttb" size="80%" :show-close="false">
        <template #header="{ header }">
            <div :id="header" class="mobile-header">
                <img class="mobile-header-logo" src="@/assets/image/logo.png" @click="goHome" />
                <div class="mobile-header-right">
                    <RouterLink class="mobile-try" :to="{ name: 'apply' }">免费试用</RouterLink>
                    <img class="mobile-status-icon" src="@/assets/image/header/close.png"
                        @click="menuShow = !menuShow" />
                </div>
            </div>
        </template>
        <div class="product-title" @click="goHome">首页</div>
        <img style="width:100% ;height:.0533rem;display: block;" src="@/assets/image/header/line.png" />
        <div class="product-title">产品与服务</div>
        <div class="menu-arr">
            <div class="menu-item" v-for="item in productMenu">
                <div class="item-top">
                    <img class="item-top-icon" :src="item.icon" />
                    <div class="item-top-title">{{ item.title }}</div>
                </div>
                <img class="line-icon" src="@/assets/image/header/line.png" />
                <div class="menu-products">
                    <div class="menu-product" v-for="product in item.list" @click="handleMenu(product)">{{ product.label
                    }}
                    </div>
                </div>
            </div>
        </div>
        <img style="width:100% ;height:.0533rem;display: block;" src="@/assets/image/header/line.png" />
        <div class="product-title">解决方案</div>
        <div v-for="plan, index in planArr" class="plan-item">
            <div class="plan-title">{{ plan.title }}</div>
            <div class="plans">
                <div class="plan" :style="{ width: index == 0 ? '50%' : '30%' }" v-for="item, idx in plan.menus"
                    @click="handleMenu(item)">
                    <img class="plan-icon" :src="plan.imgArr[idx]" />
                    <div class="plan-text">{{ item.label }}</div>
                </div>
            </div>
        </div>
        <img style="width:100% ;height:.0533rem;display: block;" src="@/assets/image/header/line.png" />
        <div class="product-title">更多</div>
        <div style="display:flex;padding:.2667rem 0 .6rem;">
            <div style="margin-right:.8533rem" v-for="about in aboutArr" @click="handleMenu(about)">{{ about.label }}
            </div>
        </div>
    </el-drawer>
</template>

<script setup>
import { ElDrawer } from 'element-plus'
import { useRouter } from 'vue-router';
const props = defineProps({ menuShow: Boolean, default: false })
const router = useRouter()
const productMenu = [
    {
        title: '移动认证',
        icon: require('@/assets/image/header/auth.png'),
        list: [
            { label: '一键登录', route: 'oneClickLogin' },
            { label: '号码标记清洗', route: 'numberClear' },
        ]
    }, {
        title: '富媒体通信',
        icon: require('@/assets/image/header/comm.png'),
        list: [
            { label: '智能云短链【AIM】', route: 'cloudShortChain' },
            { label: '视频短信', route: 'videoSms' },
        ]
    }, {
        title: '云计算产品',
        icon: require('@/assets/image/header/cloudComputing.png'),
        list: [
            { label: '负载均衡 SLB', route: 'loadBlancer' },
            { label: '云服务器ECS', route: 'cloudServe' },
            { label: '云数据库 RDS MySQL 版', route: 'cloudServeSql' },
            { label: '对象存储 OSS', route: 'objectStrorage' },
        ]
    }, {
        title: '码号服务',
        icon: require('@/assets/image/header/codeNo.png'),
        list: [
            { label: '工作号服务', route: 'jobNo' },
            { label: '隐私号业务', route: 'privacyNo', hot: true },
        ]
    }, {
        title: '语音PaaS',
        icon: require('@/assets/image/header/voice.png'),
        list: [
            { label: '呼叫中心系统', route: 'callCenter', hot: true },
            { label: '语音验证码', route: 'voicePaas' },
            // { label: '回拨外呼系统', route: 'callbackSystem' },
            { label: '数字代言人', route: 'numberMan' },
            { label: '智能外呼机器人', route: 'outboundAi' },
        ]
    }, {
        title: '运营检测',
        icon: require('@/assets/image/header/operate.png'),
        list: [
            { label: '活体实人检测', route: 'realDetection' },
            { label: '内容安全审核', route: 'safetyReview' },
            { label: '运营三要素', route: 'threeElement', hot: true },
        ]
    },]
const planArr = [{
    type: 'standard',
    title: '行业解决方案',
    imgArr: [
        require('@/assets/image/suc1.png'),
        require('@/assets/image/suc2.png'),
        require('@/assets/image/suc3.png'),
    ],
    menus: [{
        label: '银行标准解决方案',
        route: 'bank'
    }, {
        label: '政企标准解决方案',
        route: 'government'
    }, {
        label: '标准解决方案',
        route: 'standard'
    }]
}, {
    type: 'success',
    title: '成功案例',
    imgArr: [
        require('@/assets/image/suc1.png'),
        require('@/assets/image/suc2.png'),
        require('@/assets/image/suc3.png'),
        require('@/assets/image/suc4.png'),
    ],
    menus: [{
        label: '金融行业',
        route: 'success'
    }, {
        label: '政企行业',
        route: 'success'
    }, {
        label: '互联网行业',
        route: 'success'
    }, {
        label: '其他行业',
        route: 'success'
    }]
},
]
const aboutArr = [{
    label: '微趣云简介',
    route: 'about',
    value: 0
}, {
    label: '发展历程',
    route: 'about',
    value: 1
}, {
    label: '资质与荣耀',
    route: 'about',
    value: 2
}, {
    label: '合作伙伴',
    route: 'about',
    value: 3
}]
const emit = defineEmits('update:menuShow')
const handleMenu = (item) => {
    window.scrollTo(0, 0);
    router.push({
        name: item.route,
        query: {
            value: item.value || ''
        }
    });
    emit('update:menuShow', false)
};
const goHome = () => {
    window.scrollTo(0, 0);
    router.push({
        name: 'home',
    });
    emit('update:menuShow', false)
}
</script>

<style lang="scss">
.el-drawer .el-drawer__header {
    padding: 0;
    margin: 0;
}

.el-drawer .el-drawer__body {
    padding: .1333rem .4rem;
    margin: 0;
}

.product-title {
    padding: .2133rem 0;
    text-align: left;
    font-size: .3733rem;
    font-weight: 400;
    color: #111111;
}

.menu-arr {
    margin: 0 auto;
    width: 100%;
    column-count: 2;
    column-width: 240px;
    column-gap: 20px;

    .menu-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: .2333rem;

        .item-top {
            display: flex;
            align-items: center;

            &-icon {
                margin-right: .2133rem;
                width: .7rem;
                height: .7rem;
            }

            &-title {
                font-size: .4rem;
                font-weight: 500;
                color: #000000;
            }
        }

        .line-icon {
            margin: .1867rem 0;
            width: 90%;
            height: .0533rem;
        }

        .menu-products {
            text-align: left;

            .menu-product {
                font-size: .3467rem;
                font-weight: 400;
                color: #000000;
                padding-bottom: .4rem;
            }
        }
    }
}

.plan-item {
    .plan-title {
        text-align: left;
        font-size: .4267rem;
        font-weight: 500;
        color: #000000;
        padding-bottom: .2667rem;
    }

    .plans {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;

        .plan {
            display: flex;
            margin-bottom: .3733rem;

            &-icon {
                margin-right: .1333rem;
                height: .3733rem;
                width: .3733rem;
            }

            &-text {
                font-size: .3467rem;
                font-weight: 400;
                color: #000000;
            }
        }
    }
}

.mobile-header {
    height: 1.2rem;
    padding: 0 .1867rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1F67EE;

    &-logo {
        height: .8rem;
    }

    &-right {
        display: flex;
        align-items: center;

        .mobile-try {
            width: 1.8rem;
            height: .7rem;
            line-height: .7rem;
            text-align: center;
            background: #FFFFFF;
            border-radius: .16rem;
            font-size: .1867rem;
            font-weight: 600;
            color: #4A88FF;
            text-decoration: none;
        }

        .mobile-status-icon {
            margin-left: .6rem;
            height: .6rem;
        }
    }
}
</style>